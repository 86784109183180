import { getLoginLayout } from 'components/layouts/LoginLayout';
import LoginAuth from 'components/pages/user/LoginAuth';
import { NextPageWithLayout } from 'pages/_app';

const Signin: NextPageWithLayout = () => (
  <LoginAuth />
);

Signin.getLayout = getLoginLayout;

export default Signin;
