import styled from 'styled-components';

const LogoCreasStyled = styled.span`
    background: url(/svg/logo-creas-l.svg) 0 0 no-repeat;
    background-size: 100% auto;
    display: inline-block;
    height: 46px;
    text-indent: -9999px;
    width: 143px;
  `,
  LogoCreas: React.FC = (): React.ReactElement => (
    <LogoCreasStyled>CREAS</LogoCreasStyled>
  );

export default LogoCreas;
