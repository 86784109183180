import { useRouter } from 'next/router';
import styled from 'styled-components';

const LogoUuumStyled = styled.span`
    background: url(/svg/logo-uuum.svg) 0 0 no-repeat;
    background-size: 100% auto;
    cursor: pointer;
    display: inline-block;
    height: 24px;
    text-indent: -9999px;
    width: 87.5px;
  `,
  LogoUuum: React.FC = (): React.ReactElement => {
    const router = useRouter();
    return (
      <LogoUuumStyled onClick={() => router.push('/')}>
        UUUM
      </LogoUuumStyled>
    )
  }

export default LogoUuum;
