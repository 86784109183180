import LogoUuum from 'components/atoms/logo/LogoUuum';
import Layout from 'components/layouts/Layout';
import styled, { css } from 'styled-components';
import { fontSize, mediaDown } from 'styles/mixins';
import { colors, fonts, grayScales } from 'styles/variables';

const LoginLayoutStyled = styled.div`
    .login-layout {
      background: url(/signin/bg.png) 0 0 no-repeat;
      background-size: cover;
      box-sizing: border-box;
      min-height: 100vh;
      position: absolute;
      width: 100%;

      &__container {
        box-sizing: border-box;
        min-height: 100vh;
        padding-bottom: 100px;
        position: relative;

        &--header {
          .header-content {
            ${mediaDown(
    'tablet',
    css`
                height: 48px;
                padding: 10px;
              `
  )}

            height: 56px;
            padding: 16px 24px;
          }
        }

        &--body {
          align-items: center;
          display: flex;
          justify-content: center;
          min-height: 640px;
          width: 100%;
        }

        &--footer {
          bottom: 0;
          padding: 25px;
          position: absolute;
          text-align: center;
          width: 100%;

          .footer-content {
            &__contact {
              ${fontSize(14)}
              color: ${colors.black};
              font-family: ${fonts.default};
              line-height: 1.4;

              a {
                text-decoration: none;
              }
            }

            &__copyright {
              ${fontSize(16)}
              color: ${grayScales.gs400};
              margin-top: 1em;

              a {
                margin-right: 1rem;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  `,
  LoginLayout: React.FC = (props: { children?: React.ReactNode }): React.ReactElement => (
    <Layout>
      <LoginLayoutStyled>
        <div className='login-layout'>
          <div className='login-layout__container'>
            <header className='login-layout__container--header'>
              <div className='header-content'>
                <LogoUuum />
              </div>
            </header>

            <main className='login-layout__container--body'>{props.children}</main>

            <footer className='login-layout__container--footer footer-content'>
              <p className='footer-content__contact'>
                お問い合わせは、
                <a href='mailto:contact+creator@uuum.jp'>contact+creator@uuum.jp</a>
                までご連絡ください。
              </p>
              <p className='footer-content__copyright'>
                <a href='https://www.uuum.co.jp/privacy_policy'>プライバシーポリシー</a>
                Copyright &copy; UUUM Co.,Ltd. All rights reserved.
              </p>
            </footer>
          </div>
        </div>
      </LoginLayoutStyled>
    </Layout>
  ),
  getLoginLayout = (page: React.ReactElement) => (
    <LoginLayout>{ page }</LoginLayout>
  );

export { getLoginLayout };

export default LoginLayout;
