import { useState } from 'react';
import { ApiRequestInterface } from 'types/apiRequests';
import { ApiResponseErrorType } from 'types/apiResponseTypes';
import { ApiClient } from 'utils/ApiClient';

export const useCallApi = <T,>() => {
  const apiClient = new ApiClient<T>();
  const [requested, setRequested] = useState<boolean>(false);
  const [result, setResult] = useState<T | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<ApiResponseErrorType>(null);
  const [status, setStatus] = useState<number | null>(null);

  const callApi = async <U extends ApiRequestInterface>(request: U) => {
    setRequested(true);

    const response = await apiClient.requestV2(request);

    setResult(response.result);
    setMessage(response.message);
    setErrors(response.errors);
    setStatus(response.status);
    setRequested(false);

    return response;
  }

  return {
    callApi,
    errors,
    message,
    requested,
    result,
    status
  }
}
