import LogoCreas from 'components/atoms/logo/LogoCreas';
import { useCallApi } from 'hooks/useCallApi';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { fontSize, mediaDown } from 'styles/mixins';
import { colors, fonts, grayScales } from 'styles/variables';
import { InformationRequest } from 'types/apiRequests';

const LoginAuthStyled = styled.div`
    .login-auth {
      background-color: ${colors.white};
      border-radius: 4px;
      box-shadow: '0 4px 16px rgba(42, 42, 82, 0.04), 0 1px 6px rgba(42, 42, 82, 0.04)';
      font-family: ${fonts.default};
      overflow: hidden;
      position: relative;
      text-align: center;

      &__header {
        ${mediaDown(
    'tablet',
    css`
            padding: 40px 25px 15px;
          `
  )}

        padding: 40px 50px 30px;
      }

      &__body {
        ${fontSize(14)}
        ${mediaDown(
    'tablet',
    css`
            padding: 0 25px 15px;
          `
  )}

      color: ${colors.black};
        line-height: 1.71;
        padding: 0 50px 30px;

        &--button {
          background: none;
          border: none;
          cursor: pointer;
        }

        &--caution {
          ${fontSize(12)}

          color: ${grayScales.gs600};
          margin-top: 18px;
        }
      }
    }
  `,
  LoginAuth = () => {
    const { callApi } = useCallApi<string>();
    const router = useRouter();
    const pathBeforeLogin: string  = router.query.pathBeforeLogin as string ?? '/';
    const autoLogin = router.query.auto_login === 'true';
    const loginFormRef = useRef<HTMLFormElement>(null);

    
    useEffect(() => {
      if (loginFormRef.current && autoLogin) {
        loginFormRef.current.submit();
      }
    }, [loginFormRef.current, autoLogin]);

    useEffect(() => {
      (async() => {
        await callApi(new InformationRequest);
      })();
    }, []);

    return (
      <LoginAuthStyled>
        <div className='login-auth'>
          <div className='login-auth__header'>
            <LogoCreas />
          </div>
          <div className='login-auth__body'>
            <p>
              すでにUUUMネットワークに参加している方は
              <br />
              こちらよりログインしてください
              <br />
              <br />
            </p>
            <form
              ref={loginFormRef}
              action={process.env.NEXT_PUBLIC_API_URL + '/auth/oauth/one/login'}
              method='post'
              acceptCharset='UTF-8'
            >
              <input type="hidden" name="path_before_login" value={pathBeforeLogin}></input>
              <button className='login-auth__body--button' type='submit' >
                <img src='/signin/btn_google_signin_light_normal_web@2x.png' width='200px' alt='signin_button' />
              </button>
            </form>
            <p className='login-auth__body--caution'>※ Google認証へ移動します</p>
          </div>
        </div>
      </LoginAuthStyled>
    );
  }
export default LoginAuth;
